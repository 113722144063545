import { ApplicationController } from "./application_controller";

export default class extends ApplicationController {
    static targets = ["checkbox"];

    connect() {
        console.log("User form permissions controller connected");
    }

    sendDataToAlpine() {
        const data = {
            referee: this.checkboxTargets.some(checkbox => checkbox.dataset.roleType === 'official' && checkbox.checked),
            assignor: this.checkboxTargets.some(checkbox => checkbox.dataset.roleType === 'assignor' && checkbox.checked),
            observer: this.checkboxTargets.some(checkbox => checkbox.dataset.roleType === 'readonly' && checkbox.checked),
        }

        const event = new CustomEvent("stimulus:send-data", {
            detail: data,
            bubbles: true,
        });

        this.element.dispatchEvent(event);
    }

    toggleRole(event) {
        // const changedCheckbox = event.target;
        // if (changedCheckbox.dataset.roleType === 'assignor' && changedCheckbox.checked) {
        //     this.checkboxTargets
        //         .filter(checkbox => checkbox.dataset.roleType === 'readonly')
        //         .forEach(checkbox => { checkbox.checked = false; });
        // } else if (changedCheckbox.dataset.roleType === 'readonly' && changedCheckbox.checked) {
        //     const assignorCheckbox = this.checkboxTargets.find(
        //         checkbox => checkbox.dataset.roleType === 'assignor'
        //     );
        //     if (assignorCheckbox) {
        //         assignorCheckbox.checked = false;
        //     }
        // }
        this.sendDataToAlpine();
    }
}